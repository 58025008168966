<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link to="/" class="d-flex align-center">
            <a aria-label="Логотип khimkibc.events" class="logo" tabindex="0" href="/">
              <v-img width="170" :src="require('@/assets/images/khimki_bc_group.svg')"></v-img
            ></a>
          </router-link>
        </v-card-title>

        <!-- title -->

        <!-- login form -->
        <v-card-text>
          <v-form @submit.prevent="submit">
            <v-text-field
              v-model="phone"
              outlined
              label="Телефон"
              placeholder="Номер телефона"
              hide-details
              class="mb-3"
            ></v-text-field>

            <v-text-field
              v-model="password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Пароль"
              placeholder="············"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <div
              v-if="error_message"
              style="margin-top: 10px; font-size: 14px; color: #fff; padding: 20px"
              class="error"
            >
              {{ error_message }}
            </div>

            <v-btn type="submit" block color="primary" class="mt-6"> Вход </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
    <my-loader :loader="loader"></my-loader>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from 'vue'
import { mapActions } from 'vuex'
import MyLoader from '@/components/MyLoader.vue'
export default {
  components: { MyLoader },
  name: 'Login',
  setup() {
    const isPasswordVisible = ref(false)
    const phone = ref('')
    const password = ref('')
    const error_message = null
    const loader = false

    return {
      isPasswordVisible,
      phone,
      password,
      error_message,
      loader,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
  methods: {
    ...mapActions(['LogIn']),
    async submit() {
      const user = {
        phone: this.phone,
        password: this.password,
      }
      try {
        this.$set(this, 'loader', true)
        await this.LogIn(user)
        this.$router.push('/dashboard')
      } catch (error) {
        this.error_message = 'Неправильная пара логин/пароль'
        this.$set(this, 'loader', false)
      }
    },
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
